import { ADD_VARIABLE_OPTIONS } from 'constant/app/channel';

export const bodyTextLimit = 1024;
export const bodyTextLimit160 = 160;

// filter all variables
const variableRegEx = /{{([^}]*)}}/g;

const checkCurlyBrackets = /{{2}([^{}]+){2}(?=\}{2})/g;

// filter only custom variables
const customVariablesRegEx = /{{(custom-variable\d+)}}/g;

// validation patterns
const variableValidationPattern = [
  // /}}{{/g,
  /{{2}[^}]*[^}]}[^}]/g,
  /(.*?){{(.*?)}}}(.*?)/g,
  /(.*?){{{(.*?)}}(.*?)/g,
];

export const checkVariable = (value) =>
  value?.toString()?.toLowerCase()?.match(variableRegEx);

export const fliterWithCurlyBrackets = (value) =>
  value?.toString()?.toLowerCase()?.match(checkCurlyBrackets);

export function checkVariableExist(value) {
  return ADD_VARIABLE_OPTIONS.filter((item) =>
    value?.toString()?.includes(item?.value)
  );
}

export function checkVariables(value, variable) {
  if (variable !== '{{custom-variable')
    return value?.toString()?.includes(variable);

  return false;
}

export const checkIsoriginalVariable = (variable) => {
  const list = [];
  ADD_VARIABLE_OPTIONS?.map((v) => {
    if (v.label !== 'custom-variable' && v.value === variable) list.push(v);
    else if (variable?.toString()?.match(customVariablesRegEx)) list.push(v);
  });

  return list?.length > 0 ? true : false;
};

export const updateVariablesOrder = (value, variables) => {
  let result = fliterWithCurlyBrackets(value);
  let list = [];

  result?.map((r, i) => {
    const formatedVariable = `${r}}}`;
    if (checkIsoriginalVariable(formatedVariable)) {
      const data = variables?.filter((v) => v?.title === formatedVariable);
      const checkList = list?.filter((l) => l?.title === formatedVariable);
      if (data?.length > 0 && checkList?.length === 0)
        list.push({ ...data?.[0], title: formatedVariable });
      // add the variable when typing
      else if (data?.length === 0 && checkList?.length === 0)
        list.push({
          title: formatedVariable,
          value: '',
        });
    }
  });

  return list;
};

export const replaceWord = (paragraph, replacement) => {
  let updatedParagraph = paragraph;

  ADD_VARIABLE_OPTIONS.forEach(({ value }) => {
    const regex = new RegExp(value, 'g');
    updatedParagraph = updatedParagraph?.replace(regex, replacement);
  });

  return updatedParagraph;
};

export const addClosingBracket = (str) => {
  const regex = /\{+(?!})*$/g;
  return str.replaceAll(regex, `{{$1}}`);
};

export const getVariableCount = (value) =>
  value?.toString()?.toLowerCase()?.match(variableRegEx);

export const getCustomVariableCount = (value) =>
  value?.toString()?.toLowerCase()?.match(customVariablesRegEx);

export const replaceCustomVariableCount = (value) => {
  var count = 1;
  var updatedStr = value?.replaceAll(
    customVariablesRegEx,
    function (match, variable) {
      return `{{custom-variable${count++}}}`;
    }
  );

  return updatedStr;
};

export const checkVariablesAreValid = (value) => {
  const res = variableValidationPattern?.map((p, i) => {
    var updatedStr = value?.toString().toLowerCase().match(p);
    return updatedStr?.length > 0 ? true : false;
  });
  return res?.filter((r) => r === true)?.length > 0 ? true : false;
};

export const getVariablesCount = (value) => {
  let count = 1;

  value = value?.replaceAll?.(/{{first-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{last-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{shop-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{email-id}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{mobile-number}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(customVariablesRegEx, function (match, variable) {
    return `${count++}`;
  });

  return value;
};

export const getReplacedVariablesCount = (value) => {
  let count = 1;

  value = value?.replaceAll?.(/{{first-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{last-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{shop-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{email-id}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{mobile-number}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(customVariablesRegEx, function (match, variable) {
    return `${count++}`;
  });

  return count;
};
